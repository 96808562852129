import {ZadostSopWebBlokRozcestnikInput} from '@eon.cz/apollo13-graphql-web';
import {useDispatch} from 'react-redux';
import {setKomoditaSlice} from '../../../common/reducers/CommonReducer';
import {setRozcestnikDataSlice} from '../reducer/RozcestnikReducer';

type RozcestnikActionType = {
    setRozcestnikData: (input: Omit<ZadostSopWebBlokRozcestnikInput, 'email'>) => void;
};

/**
 * Custom hook pro redux akci rozcestníku
 *
 * @return {*}  {RozcestnikActionType}
 */
export const useRozcestnikAction = (): RozcestnikActionType => {
    const dispatch = useDispatch();
    return {
        setRozcestnikData: (input) => {
            dispatch(setKomoditaSlice(input.komodita));
            dispatch(setRozcestnikDataSlice(input));
        },
    };
};
