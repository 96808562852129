import {gql} from '@apollo/client';

export const CommonQueries = {
    gql: {
        formInfo: gql`
            query FormInfo($parseId: MongoObjectId!, $callGeoPortal: Boolean!, $canCallBlock: Boolean!) {
                sopWeb {
                    formInfo(parseId: $parseId) {
                        form {
                            error
                            errorMessage
                            formType
                            formKind
                            id
                            lastFinishedBlok {
                                id
                                typ
                            }
                            parseId
                            stav
                            vytvoreno
                            formStepPath
                        }
                        found
                    }
                    nastaveni {
                        sopWebFormOutages {
                            aktivni
                            formKind
                            formZakazanHtml
                            id
                        }
                    }
                    geoPortalBlok(parseId: $parseId) @include(if: $callGeoPortal) {
                        completed
                    }
                    rozcestnikBlok(parseId: $parseId) @include(if: $canCallBlock) {
                        uzivatelData {
                            email
                            jmeno
                            prijmeni
                            telefon
                            uzivatelId
                        }
                        skupinaData {
                            prijmeni
                            jmeno
                            adresa {
                                cisloOrientacni
                                cisloPopisne
                                mesto
                                mistniCast
                                psc
                                ulice
                            }
                            datumNarozeni
                            ico
                            nazev
                            obchodniPartner
                            skupinaId
                            typ
                        }
                    }
                    zakaznikBlok(parseId: $parseId) @include(if: $canCallBlock) {
                        typZakaznika
                    }
                }
                nastaveni {
                    prilohy {
                        povoleneTypyPriloh {
                            typObjektu
                            typyPriloh
                            id
                        }
                    }
                }
            }
        `,
        info: gql`
            query InfoForm($parseId: MongoObjectId!, $callGeoPortal: Boolean!) {
                sopWeb {
                    formInfo(parseId: $parseId) {
                        form {
                            formType
                            id
                            lastFinishedBlok {
                                id
                                typ
                            }
                            parseId
                            formStepPath
                        }
                    }
                    geoPortalBlok(parseId: $parseId) @include(if: $callGeoPortal) {
                        completed
                    }
                }
            }
        `,
        formOff: gql`
            query FormOffInfo {
                sopWeb {
                    nastaveni {
                        sopWebFormOutages {
                            aktivni
                            formKind
                            formZakazanHtml
                            id
                        }
                    }
                }
            }
        `,
        om: gql`
            query NaseptavacOdbernaMista($input: String!, $parseId: String!) {
                adresniMista {
                    fulltextAdresniMista(
                        first: 5
                        filter: {adresa: $input, fulltextFields: [ulice, cisloEvidencni, cisloOrientacni, cisloPopisne, psc, mesto, mistniCast]}
                        parseId: $parseId
                    ) {
                        edges {
                            node {
                                id
                                cisloEvidencni
                                ulice
                                cisloOrientacni
                                cisloPopisne
                                mesto
                                mistniCast
                                psc
                            }
                        }
                    }
                }
            }
        `,
        parcela: gql`
            query NaseptavacParcela($input: String!, $parseId: String!) {
                parcely {
                    fulltext(first: 5, filter: $input, uuid: $parseId) {
                        edges {
                            node {
                                id
                                kodKatastralnihoUzemi
                                nazevKatastralnihoUzemi
                                mesto
                                psc
                                kmenoveCislo
                                pododdeleniCisla
                            }
                        }
                    }
                }
            }
        `,
        ares: gql`
            query Ares($ic: String!) {
                ares {
                    companyDataByIco(ic: $ic) {
                        dic
                        nazevFirmy
                        adresa {
                            ulice
                            cisloEvidencni
                            cisloOrientacni
                            cisloPopisne
                            obec
                            mistniCast
                            psc
                        }
                    }
                }
            }
        `,

        geoportal: gql`
            query Geoportal($parseId: MongoObjectId!) {
                sopWeb {
                    geoPortalBlok(parseId: $parseId) {
                        parseId
                        TID
                        completed
                        id
                        redirectUrl
                    }
                }
            }
        `,
        zadostElektrinaSopDruhVyrobnyElektriny: gql`
            query ZadostElektrinaSopDruhVyrobnyElektriny {
                sopWeb {
                    nastaveni {
                        zadostElektrinaSopDruhVyrobnyElektriny {
                            id
                            aktivni
                            klic
                            nazev
                            popis
                            poradi
                            vychozi
                        }
                    }
                }
            }
        `,
        zadostElektrinaSopDistribucniSazba: gql`
            query ZadostElektrinaSopDistribucniSazba(
                $proSopWebNovyOdberKratkodoby: Boolean
                $proSopWebNovyOdberTrvaly: Boolean
                $isDruhPartneraFO: Boolean
                $isDruhPartneraPO: Boolean
            ) {
                sopWeb {
                    nastaveni {
                        distribucniSazbaElektrina(
                            proSopWebNovyOdberKratkodoby: $proSopWebNovyOdberKratkodoby
                            proSopWebNovyOdberTrvaly: $proSopWebNovyOdberTrvaly
                            isDruhPartneraFO: $isDruhPartneraFO
                            isDruhPartneraPO: $isDruhPartneraPO
                        ) {
                            id
                            aktivni
                            klic
                            nazev
                            popis
                            poradi
                            vychozi
                            isDruhPartneraFO
                            isDruhPartneraPO
                        }
                    }
                }
            }
        `,
        zadostElektrinaSopDruhOdberu: gql`
            query ZadostElektrinaSopDruhOdberu($zahrnoutNeaktivni: Boolean, $kratkodobyOnly: Boolean, $typZakaznika: ZadostSopObchodniPartnerDruh!) {
                sopWeb {
                    nastaveni {
                        zadostElektrinaSopNovyOdberDruhOdberu(
                            zahrnoutNeaktivni: $zahrnoutNeaktivni
                            kratkodobyOnly: $kratkodobyOnly
                            typZakaznika: $typZakaznika
                        ) {
                            id
                            aktivni
                            klic
                            nazev
                            popis
                            poradi
                            vychozi
                        }
                    }
                }
            }
        `,
        zadostElektrinaSopSpotrebicTyp: gql`
            query ZadostElektrinaSopSpotrebicTyp($kratkodoby: Boolean, $trvaly: Boolean) {
                sopWeb {
                    nastaveni {
                        zadostElektrinaSopNovyOdberSpotrebicTyp(kratkodoby: $kratkodoby, trvaly: $trvaly) {
                            id
                            aktivni
                            klic
                            nazev
                            popis
                            poradi
                            vychozi
                        }
                    }
                }
            }
        `,

        zadostPlynSopTypSpotrebice: gql`
            query ZadostPlynSopTypSpotrebice {
                sopWeb {
                    nastaveni {
                        zadostPlynSopNovyOdberTypSpotrebice {
                            id
                            aktivni
                            klic
                            nazev
                            popis
                            poradi
                            vychozi
                        }
                    }
                }
            }
        `,
        zadostSopTypJistice: gql`
            query ZadostSopTypJistice {
                sopWeb {
                    nastaveni {
                        zadostSOPHodnotaJistice {
                            id
                            aktivni
                            poradi
                            vychozi
                            hodnota
                            jednofaze
                            trifaze
                            popis
                        }
                    }
                }
            }
        `,
        nastaveni: gql`
            query Nastaveni {
                nastaveni {
                    prilohy {
                        povoleneTypyPriloh {
                            typObjektu
                            typyPriloh
                            id
                        }
                    }
                }
            }
        `,
        zadostPlynSopVyuzitiOdbernehoMista: gql`
            query ZadostPlynSopVyuzitiOdbernehoMista($zahrnoutNeaktivni: Boolean, $typZakaznika: ZadostSopObchodniPartnerDruh!) {
                sopWeb {
                    nastaveni {
                        zadostPlynSopNovyOdberVyuzitiOdbernehoMista(zahrnoutNeaktivni: $zahrnoutNeaktivni, typZakaznika: $typZakaznika) {
                            id
                            aktivni
                            klic
                            nazev
                            popis
                            poradi
                            vychozi
                            kombinaceFo
                            kombinacePo
                        }
                    }
                }
            }
        `,
    },
};
