import {Link, Paper, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {FC, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {NotificationModel} from '../model/NotificationModel';

type Props = {
    readonly notification?: NotificationModel;
    readonly moreMessages: number;
};

const useStyles = makeStyles((theme: Theme) => ({
    text: {
        paddingRight: theme.spacing(0),
    },
    showErrorLink: {
        opacity: 0.8,
        fontWeight: 'lighter',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    pre: {
        padding: theme.spacing(1),
        color: theme.palette.text.primary,
        overflow: 'auto',
    },
    more: {
        fontWeight: 'lighter',
        fontStyle: 'italic',
        opacity: 0.6,
    },
}));

export const NotificationsMessageComponent: FC<Props> = ({notification, moreMessages}) => {
    const [errorShown, setErrorShown] = useState<boolean>(false);
    const handleShowError = () => setErrorShown(true);
    const classes = useStyles();

    if (notification === undefined) {
        return <span />;
    }

    return (
        <>
            <Typography color="inherit" className={classes.text}>
                {notification.text}
                {notification.errorObject && !errorShown && (
                    <span>
                        &nbsp;{' '}
                        <Link onClick={handleShowError}>
                            <FormattedMessage id="(zdroj)" />
                        </Link>
                    </span>
                )}
                {moreMessages > 0 && (
                    <span className={classes.more}>
                        &nbsp;{'+'}
                        {moreMessages}
                    </span>
                )}
            </Typography>
            {notification.errorObject && errorShown && (
                <Paper>
                    <pre className={classes.pre}>{JSON.stringify(notification.errorObject, null, 2)}</pre>
                </Paper>
            )}
        </>
    );
};
