import {ErrorOutlineOutlined} from '@mui/icons-material';
import {Button, Typography} from '@mui/material';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {sanitizeHTML} from '../../utils/CommonUtils';
import {Div} from '../styled/Div';

type Props = {
    readonly message: string;
    readonly refetch: () => void;
};

/**
 * Komponenta pro zobrazení informace při vypnutí formuláře z admin portálu
 *
 * @param {*} {message, refetch}
 */
export const FormOff: FC<Props> = ({message, refetch}) => {
    const {push} = useRouter();

    /**
     * Přesměruje uživatele na hlavní stránku (root)
     */
    const handleRootRedirect = () => push('/');
    const handleRefresh = () => refetch();
    return (
        <Div
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 'auto',
                width: '80%',
                gap: 5,
            }}
        >
            <ErrorOutlineOutlined style={{fontSize: 150}} color="error" />
            <Div
                sx={{
                    padding: 0,
                }}
            >
                <Typography variant="h6" color="inherit" dangerouslySetInnerHTML={{__html: sanitizeHTML(message)}} />
            </Div>
            <Div
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    size="medium"
                    color="primary"
                    sx={{
                        marginRight: 2,
                    }}
                    variant="contained"
                    disableRipple
                    onClick={handleRootRedirect}
                >
                    <FormattedMessage id="button.root" />
                </Button>
                <Button size="medium" color="primary" variant="contained" disableRipple onClick={handleRefresh}>
                    <FormattedMessage id="button.aktualizovat" />
                </Button>
            </Div>
        </Div>
    );
};
