import {CircularProgress, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle} from '@mui/material';

import {FC, memo} from 'react';
import {FormattedMessage} from 'react-intl';

export type LoadingDialogProps = DialogProps & {
    /**
     * Description message ID
     */
    readonly description?: string;

    /**
     * Restore focus after dialog is closed
     */
    readonly restoreFocus?: boolean;
};

/**
 * Render loading dialog
 */
export const LoadingDialog: FC<LoadingDialogProps> = memo(({description, restoreFocus, ...props}) => {
    return (
        <Dialog disableRestoreFocus={!restoreFocus} {...props}>
            <FormattedMessage id="cekejte.prosim">
                {(msg) => (
                    <DialogTitle>
                        {msg}
                        <FormattedMessage id="symbol.tecky" />
                    </DialogTitle>
                )}
            </FormattedMessage>
            <DialogContent
                sx={{
                    textAlign: 'center',
                }}
            >
                {description && (
                    <DialogContentText>
                        <FormattedMessage id={description} />
                    </DialogContentText>
                )}
                <CircularProgress
                    sx={{
                        color: 'primary.main',
                    }}
                />
            </DialogContent>
        </Dialog>
    );
});
